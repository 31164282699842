import React, { useEffect } from "react";
import * as Sentry from "@sentry/react";
import "sweetalert2/src/sweetalert2.scss";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import AppRoutes from "routes";
import AuthGuard from "components/Auth/AuthGuard";
import { AppProvider } from "hooks/useApp";
import { BrowserRouter } from "react-router-dom";
import { Toaster, ToastBar, toast, useToaster } from "react-hot-toast";
import { useAppTheme } from "providers/AppThemeProvider";
import { useAuth } from "state/selector";
import { useIdleTimer } from "react-idle-timer";
import { redirectLogout } from "utils/auth";
import config from "config";

const App = () => {
  const { theme } = useAppTheme();
  const { user } = useAuth();
  const { handlers } = useToaster();
  const { startPause, endPause } = handlers;

  const onIdle = () => {
    if (config.env !== "development") redirectLogout();
  };

  useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000, // 30 minutes
    // Throttling reduces the number of times mouse actions are registered to once every 500ms
    // So rather than registering hundreds of mouse move events, we collapse them into one every 500ms
    throttle: 500,
    crossTab: true, // Cross-tab Idle Detection
  });

  // Set user on Sentry
  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, name: user.name, email: user.email });
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthGuard>
        <BrowserRouter>
          <AppProvider>
            <AppRoutes />
          </AppProvider>
          <Toaster
            position="bottom-right"
            toastOptions={{
              style: {
                background: "#0F172A",
                color: "white",
              },
              success: { duration: Infinity },
              error: { duration: Infinity },
            }}
          >
            {t => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <div onMouseEnter={startPause} onMouseLeave={endPause} style={{ display: "flex" }}>
                    {icon}
                    {message}
                    {t.type !== "loading" && (
                      <div
                        style={{ width: "100%", height: "100%", position: "absolute", cursor: "pointer" }}
                        onClick={() => toast.dismiss(t.id)}
                      />
                    )}
                  </div>
                )}
              </ToastBar>
            )}
          </Toaster>
        </BrowserRouter>
      </AuthGuard>
    </ThemeProvider>
  );
};

export default App;
